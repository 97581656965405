import React from 'react'

function Footer() {
  return (
    <div id="footer">
    <div className="container text-center">
      <p>
        &copy;Copyrights 2024 | Task Elephants | All Rights Reserved
      </p>
    </div>
  </div>
  )
}

export default Footer